<!--
 * @Author: wb_guochuang wb_guochuang@kuaishou.com
 * @Date: 2022-06-05 17:57:07
 * @LastEditors: wb_guochuang wb_guochuang@kuaishou.com
 * @LastEditTime: 2022-07-12 22:28:38
 * @FilePath: \personal\src\components\AddOrEditModal.vue
 * @Description: 提示 or 下载
-->
<template>
<div>
  <a-modal
    class="edit-box-modal"
    :width="360"
    :title="title || '提示'"
    :visible="visible"
    :maskClosable="false"
    @cancel="handleClose"
  >
    <template slot="footer">
      <div class="button">
        <div @click="handleClose" class="cancle">取消</div>
        <div @click="handleSubmit" class="ok">确定</div>
      </div>
    </template>

    <div class="modal-content">
      <div class="content">
        <div v-html="msg" />
        <a-form :form="form" :layout="formLayout" :wrapper-col="{ span: 24 }">
          <a-form-item class="book-name">
            <a-input
              v-decorator="['bookName', { rules: [{ required: true, message: '请输入图书名称' }] }]"
              placeholder="不超过20个字"
              :maxLength="20"
            />
          </a-form-item>
        </a-form>
      </div>
    </div>
  </a-modal>
</div>

</template>

<script>
export default {
  props: [
    'visible',
    'handleClose',
    'handleOk',
    'msg',
    'title'
  ],
  data() {
    return {
      form: this.$form.createForm(this, { name: 'coordinated' }),
      formLayout: 'vertical',
    }
  },
  methods: {
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (err) {
          console.log('Received values of form: ', values);
        } else {
          this.$emit('handleOk', values.bookName)
        }
      });
    }
  }
}
</script>

<style lang="less">
.edit-box-modal {
  .ant-modal-content {
    .ant-modal-header {
      border:  none;
      padding-bottom: 0;

      .ant-modal-title {
        width: 80px;
        height: 32px;
        border-bottom: 5px solid #6868CE;
        display: flex;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        color: #6868CE;
      }
    }

    .ant-modal-footer {
      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 46px;

        div {
          width: 84px;
          height: 34px;
          border: 1px solid #9B9B9B;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-weight: 500;
          color: #9B9B9B;
          cursor: pointer;
        }

        .cancle:hover {
          border: 1px solid #6868CE;
          color: #6868CE;
        }

        .ok:hover {
          opacity: 0.9;
        }

        .ok {
          background: url(../assets/beijing.png) no-repeat;
          background-size: cover;
          color: #fff;
          border: none;
          margin-left: 50px;
        }
      }
    }

    .modal-content {
      width: 100%;
      min-height: 160px;
      max-height: 300px;
      overflow-y: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .content {
        width: 100%;
        height: 100%;
        font-size: 14px;
        font-weight: 400;
        color: #120808;
        line-height: 28px;
      }
    }
  }
}

</style>