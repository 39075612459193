<template>
  <div>
    <div v-for="(item, index) in list" :key="item.id" class="list-wrap">
      <Checkbox 
        @change="handleChange($event, item.id)"
        :checked="checkedList.indexOf(item.id) == -1 ? false : true"
      />
      <div class="list-item">
        {{ item.bookBrogressSimple }}
        <span class="simple" v-if="item.bookIsSimple == 1">简体</span>
        <span class="simple" v-if="item.bookIsSimple == 0">繁体</span>
        <div class="list-left">
          <div class="left">
            <img
              class="cover"
              :src="item.coverImage ? 
              `${imgPath}${item.coverImage}`
              : require('@/assets/image-default.png')" 
             />
            
            <div
              class="upload"
              v-if="item.bookProgressOcr != 2 && item.bookType == 0"
            >
              <input
                type="file"
                multiple="multiple"
                id="files"
                accept="image/png,image/jpeg,image/tiff,image/tif,application/pdf,.djvu"
                @change="handleImgChange(item.id, item.countPage, $event)"
              />
              <span>上传文件</span>
            </div>
            <img src="@/assets/biaoqian.png" alt=""  class="img" @click="handleEdit(item.id)"/>
          </div>
          <div class="right">
            <div class="bookName">
              <div />
              <span> 《{{item.bookName}}》 </span>
            </div>
            <div class="bookContent">
              <template v-if="item.bookType == 1">
                <span>格式：{{item.bookFormal}}</span>
                <span>字数：{{item.countWord}}</span>
                <span>创建时间：{{item.sysCreateTime}}</span>
                <span v-if="item.sysHandleTime">最新保存：{{item.sysHandleTime}}</span>
                <span v-if="item.endHandleTime" style="color: #666;">保存期限：{{ item.endHandleTime }}</span>
              </template>
              <template v-else>
                <span>页数：{{item.countPage}}页</span>
                <span>识别页数：{{item.countOcrPage}}</span>
                <span>字数：{{item.countWord}}</span>
                <span>修改字数：{{item.countEdit}}</span>
                <span>创建时间：{{item.sysCreateTime}}</span>
                <span v-if="item.sysHandleTime">最新保存：{{item.sysHandleTime}}</span>
                <span v-if="item.endHandleTime" style="color: #666;">保存期限：{{ item.endHandleTime }}</span>
              </template>
            </div>
            <div class="bookSchedule">
              <span>进度</span>
              <!-- .filter(item => item.fanJianStatus != 2 ) -->
              <img
                v-for="itm in scheduleArr"
                :key="itm.title" 
                v-if="itm.title != 'bookProgressEdit'"
                :src="
                item[itm.title] === 0 ? itm.unChecked
                : item[itm.title] === 1 ? itm.checked 
                : item[itm.title] === 2 ? itm.error : ''"
              >
            </div>
          </div>
        </div>
        <div class="list-right">
          <template
            v-for="itm in scheduleArr"
          >
            <template v-if="itm.title == 'bookProgressImg'"></template>
            <!-- 繁简转换按钮判断是否显示 -->
            <template v-else-if="(itm.title == 'bookProgressSimple'&& item.transSimpleFlag == 1) 
            || (itm.title == 'bookProgressComplexity' && item.transComplexityFlag == 1)"> 
              <div class='step-click active' @click="firstTips(itm, item)">
                <img :src="itm.checked">
                <span>{{itm.text}}</span>
              </div>
            </template>
            <template v-else-if="item[itm.type] == 1">
              <div class='step-click active' @click="firstTips(itm, item)">
                <img :src="itm.checked">
                <span>{{itm.text}}</span>
              </div>
            </template>
          </template>
          <!-- <div class='step-click active jump-btn' @click="jumpWPS(item.id)" v-if="item.editFlag == 1">
            <img src="@/assets/icon-wsp.png">
            <span>金山文档</span>
          </div> -->
          <div class='step-click active jump-btn' @click="jumpTx(item.id)" v-if="item.editFlag == 1">
            <img src="@/assets/icon-tx.jpg" />
            <span>腾讯文档</span>
          </div>
          <!-- <div class='step-click active jump-btn' @click="jumpFs(item.id)" v-if="item.editFlag == 1">
            <img src="@/assets/icon-fs.png" />
            <span>飞书文档</span>
          </div> -->
        </div>
      </div>
    </div>

    <AddOrEditModal 
      v-if="editVisible"
      type="edit"
      :visible="editVisible"
      :id="editId"
      :handleClose="handleClose"
      :handleOcrList="handleOcrList"
    />

    <HintOrDownModal
      v-if="tipsVisible"
      title="提示"
      :tipsType="
        tipsStep == 'first' ? 'tipsFourteen'
        : (titleType == 'bookProgressSimple' || titleType == 'bookProgressComplexity' ) && tipsStep == 'one' ? 'tipsFive'
        : tipsStep == 'one' ? 'tipsThirteen'
        : tipsStep == 'loading' ? 'tipsTen'
        : 'tipsNine'"
      :visible="tipsVisible"
      :handleClose="handleTipsClose"
      :handleCallback="handleTipsCallBack"
      :fanJianStatus="tipsStep != 'loading' ? itm.fanJianStatus : ''"
    />

    <!-- 提示 -->
    <TipsModal
      v-if="imgTipsVisible"
      :visible="imgTipsVisible"
      tipsType="tipsFour"
      buttonText="我知道了"
      :handleClose="tipsImageCallBack"
      :limitImageObj="limitImageObj"
    />

    <!-- 上传失败弹窗提示 -->
    <UploadModal
      v-if="upFailVisible"
      :visible='upFailVisible'
      :handleClose='closeUploadModal'
      :exceedFiveList='exceedFiveList'
      :jamList='jamList'
      :type='upFailCondition'
      :fileFailCount="fileFailCount"
    />

    <!-- 图书重复修改 -->
    <EditBookModal
      v-if="editBookVisible"
      :visible='editBookVisible'
      :msg="bookRepeatMsg"
      :handleClose='closeBookModal'
      @handleOk='submitBookModal'
    />

    <!-- 识别额度提示 -->
    <HintOcrModal
      :visible="tipsOcrVisible"
      :handleClose="handleTipsOcrClose"
      :handleCallback="handleTipsOcrCallBack"
      :ocrLimitObj="limitObj"
    />
    
  </div>
</template>
<script>
import { Checkbox  } from 'ant-design-vue';
import unCheckedPic from '@/assets/picture.png';
import unCheckedOcr from '@/assets/OCR-g.png';
import unCheckedText from '@/assets/wenben.png';
import unCheckedA from '@/assets/daxieA.png';
import unCheckedTransform from '@/assets/fanjian.png'
import unCheckedTransform2 from '@/assets/jianfan.png'

import checkedPic from '@/assets/checkedPic.png';
import checkedOcr from '@/assets/checkedOcr.png';
import checkedText from '@/assets/checkedText.png';
import checkedA from '@/assets/checkedA.png';
import checkedTransform from '@/assets/checkedFanjian.png'
import checkedIconEdit from '@/assets/icon-edit.png'
import checkedTransform2 from '@/assets/checkedJianfan.png'

import errorPic from '@/assets/picture-red.png';
import errorOcr from '@/assets/ocr-red.png';
import errorText from '@/assets/text-red.png';
import errorA from '@/assets/a-red.png';
import errorTransform from '@/assets/fanjian-red.png';
import errorTransform2 from '@/assets/jianfan-red.png';

import { textTransform, textAutopun, metabookProgress, weboffice,
  tencent, getImglist, checkBookName, feishu, getLimitImageBook} from '@/api/ocr';
import { consumeCheck } from '@/api/institution'
import AddOrEditModal from '@/components/AddOrEditModal.vue';
import HintOrDownModal from '@/components/HintOrDownModal.vue';
import Cookies from 'js-cookie';
import axios from 'axios'
import { getToken } from '@/utils/auth'
import TipsModal from '@/components/TipsModal.vue'
import UploadModal from '@/components/UploadModal.vue';
import EditBookModal from '@/components/EditBookModal.vue';
import HintOcrModal from '@/components/HintOcrModal.vue';

export default {
  components: { Checkbox , AddOrEditModal, HintOrDownModal,
    TipsModal, UploadModal, EditBookModal, HintOcrModal },
  props: [ 'list', 'handleCheckedBook', 'checkedList', 'openUrlLoading'],
  data() {
    return {
      scheduleArr: [
        {
          title: 'bookProgressImg',
          type: '',
          checked: checkedPic,
          unChecked: unCheckedPic,
          error: errorPic,
          text: '图文校对'
        },
        {
          title: 'bookProgressOcr',
          checked: checkedOcr,
          unChecked: unCheckedOcr,
          error: errorOcr,
          text: 'ocr识别',
          type: 'ocrFlag',
          id: 0
        },
        {
          title: 'bookProgressBd',
          checked: checkedText,
          unChecked: unCheckedText,
          error: errorText,
          text: '标点',
          type: 'bdFlag',
          id: 1
        },
        {
          title: 'bookProgressBx',
          checked: checkedA,
          unChecked: unCheckedA,
          error: errorA,
          text: '标点标线',
          type: 'bxFlag',
          id: 2
        },
        {
          title: 'bookProgressSimple',
          checked: checkedTransform,
          unChecked: unCheckedTransform,
          error: errorTransform,
          text: '繁转简',
          type: 'transFlag',
          fanJianStatus: 1,
          id: 3
        },
        {
          title: 'bookProgressComplexity',
          checked: checkedTransform2,
          unChecked: unCheckedTransform2,
          error: errorTransform2,
          text: '简转繁',
          type: 'transFlag',
          fanJianStatus: 2,
          id: 4
        },
        {
          title: 'bookProgressEdit',
          checked: checkedIconEdit,
          unChecked: checkedIconEdit,
          error: checkedIconEdit,
          text: '在线编校',
          type: 'editFlag',
          id: 5
        },
      ],
      editVisible: false,
      editId: '',
      imgPath: process.env.VUE_APP_IMG_PATH,
      tipsVisible: false,
      titleType: '',
      tipsBookId: '',
      tipsStep: 'one',
      id: '', 
      bookName: '',
      userInfo: {},
      loading: false,
      imgTipsVisible: false, // 图片超出提示
      upFailVisible: false,
      upFailCondition: 'all', // all 全部 a 情况a b 情况b
      // 超过5兆照片集合
      exceedFiveList: [],
      //拥堵集合
      jamList: [],
      jumpWPSLoading: false,
      jumpTxLoading: false,
      jumpFsLoading: false,
      bookRepeatMsg: '',
      editBookVisible: false,
      newBookName: '',
      tipsOcrVisible: false, // 判断额度提示
      limitObj: {}, // 提示需要的动态文字对象
      limitImageObj: {}
    }
  },

  mounted() {
    this.userInfo = Cookies.get('userInfo') ? JSON.parse(Cookies.get('userInfo')) : {}
  },
  methods: {
    // 文本处理提示
    async firstTips(itm, item) {
      if (item[itm.title] == 2 ) {
        this.$message.warning('此状态下禁止操作');
        return
      }
      this.itm = itm
      this.item = item

      const res = await metabookProgress({
        bookid: item.id,
        button: itm.id
      })

      // 判断是否有正在识别的任务
      if (res.data.progress) {
        return this.$message.error(res.data.progress);
      }

      // 判断是否点击过
      if (res.data.hadDeal == 1 || item.bookType == 1) {
        this.onChangeText()
      } else {
        this.tipsVisible = true 
        this.tipsStep = 'first'
      }
    },
    // 提示确认后进入文本处理的逻辑
    async onChangeText() {
      // 在线编校
      if (this.itm.title == 'bookProgressEdit') {
        this.tipsVisible = false;
        setTimeout(() => {
          window.open(`${process.env.VUE_APP_EDIT}?sysBiggerId=${this.item.id}&jump=editTask`); 
        }, 500)
        return
      }

      // ocr识别
      if (this.itm.title === 'bookProgressOcr') {
        this.goBookDetail(this.item.id, this.item.bookName, this.item.bookProgressOcr)
      } else {
        this.loading = false
        this.tipsVisible = true 
        this.titleType = this.itm.title
        this.tipsBookId = this.item.id
        this.tipsStep = 'one'
      }
    },
    /** 复选框 */
    handleChange(e, id) {
      const { checked }  = e.target
      this.handleCheckedBook(id, checked)
    },
    // 编辑弹层显示
    handleEdit(id) {
      this.editId = id
      this.editVisible = true
    },
    handleClose() {
      this.editVisible = false
    },
    // 刷新列表
    handleOcrList() {
      this.$emit('refresh')
    },
    // 跳转到ocr识别页面
    async goBookDetail(id, bookName, ocrStatus) {
      try {
        await getImglist({
          sysBookId: id,
        })
        this.$router.push(`/ocrImg?id=${id}&bookName=${bookName}&ocrStatus=${ocrStatus}`)
      } catch (error) {
        this.$emit('refresh')
      }
    },
    // 关闭提示
    handleTipsClose() {
      this.tipsVisible = false
    },
    // 提示确定 1.默认点击确定等待
    async handleTipsCallBack() {
      if (this.tipsStep == 'first') {
        this.$emit('refresh')
        this.onChangeText()
        return
      } else if (this.tipsStep == 'one') {
        this.tipsStep = 'two'

        this.tipsVisible = false
        const consumeRes = await consumeCheck({
          ids: this.tipsBookId,
          type: this.titleType,
          institutionId: this.userInfo.institutionId
        })
        this.limitObj = consumeRes.data
        this.tipsOcrVisible = true
      } else { // 点击稍候查看
        this.tipsVisible = false
        this.$message.success('处理中,请到消息中心查看结果');
        return
      }

      // 避免点击稍候查看按钮重复提示
      // if (this.tipsVisible) {
      //   this.tipsVisible = false
      //   this.$message.success('处理完成');
      //   this.$emit('refresh')
      // }
    },
    // 上传图片 
    async handleImgChange(bookId, countPage, info) {
      this.loading = true;
      let formData = new FormData()
      let filesList = Object.values(info.target.files)
      this.uploadURL = process.env.VUE_APP_BASE_API + `ocr/uploadImgs/${bookId}`

      const res = await getLimitImageBook({
        bookId: bookId,
      })

      if (res.data.isBookLimit) {
        let num = 0
        filesList.map(item => {
          num = num + item.size
        })
        if (num > res.data.thisBookMaxLimitToB) {
          this.limitImageObj = res.data
          this.imgTipsVisible = true
          this.loading = false;
          document.querySelector('#files').value = null
          return
        }
      }

      let fileFlag = false
      filesList.map(item => {
        if ('application/pdf.djvu'.indexOf(item.type) != -1 && filesList.length > 1) {
          fileFlag = true
        }
        formData.append('files', item)
      })

      // 单个Pdf或Djvu
      if (fileFlag) {
        this.$message.error('一本书仅支持上传单个Pdf或Djvu，请您分多本书上传。');
        this.loading = false;
        document.querySelector('#files').value = null
        return
      }

      this.tipsStep = 'loading'
      this.tipsVisible = true

      axios({
        url: this.uploadURL,
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + getToken(),
        },
        data: formData
      })
      .then((response) =>{
        this.tipsVisible = false
        if (response.data.code == 200) {
          if (response?.data?.data) {
            this.exceedFiveList = response.data.data.imgNameFailSizeList
            this.jamList = response.data.data.imgNameFailErrorList
            this.upFailVisible = true
            this.fileFailCount = response.data.data.fileFailCount
          } else {
            this.$message.success('上传成功');
          }
        } else {
          this.$message.error(response.data.msg || '上传有误!');
        }
       
        this.loading = false;
        document.querySelector('#files').value = null
        this.$emit('refresh')
      })
      .catch((error) => {
        document.querySelector('#files').value = null
        this.loading = false;
        this.$message.error(error.data.msg || '上传有误!');
      })
    },
    // 图片超限提示
    tipsImageCallBack() {
      this.imgTipsVisible = false
    },
    closeUploadModal() {
      this.upFailVisible = false
    },
    // 跳转WPS
    async jumpWPS(id) {
      if (this.jumpWPSLoading) {
        return
      }

      this.jumpWPSLoading = true
      this.$emit('openUrlLoading', true)
      
      try {
        const res = await weboffice({
          bookId: id
        })

        this.jumpWPSLoading = false
        this.$emit('openUrlLoading', false)
        this.openNewWindow(res.data.url)
      } catch (error) {
        this.$emit('openUrlLoading', false)
        this.jumpWPSLoading = false
        console.log('error', error)
      }
    },
    // 腾讯
    async jumpTx(id) {
      if (this.jumpTxLoading) {
        return
      }

      this.jumpTxLoading = true
      this.$emit('openUrlLoading', true)

      try {
        const res = await tencent({
          bookId: id
        })

        this.$emit('openUrlLoading', false)
        this.jumpTxLoading = false

        this.openNewWindow(res.data.url)
      } catch (error) {
        this.$emit('openUrlLoading', false)
        this.jumpTxLoading = false
        console.log('error', error)
      }
    },
    // 飞书
    async jumpFs(id) {
      if (this.jumpFsLoading) {
        return
      }

      this.jumpFsLoading = true
      this.$emit('openUrlLoading', true)

      try {
        const res = await feishu({
          bookId: id
        })

        this.$emit('openUrlLoading', false)
        this.jumpFsLoading = false

        this.openNewWindow(res.data.url)
      } catch (error) {
        this.$emit('openUrlLoading', false)
        this.jumpFsLoading = false
        console.log('error', error)
      }
    },
    /**
     * 创建一个跳转的a元素，模拟发送click请求后将该元素从文档中删除
     */
    openNewWindow(url) {
      document.getElementById("openNewWindow").href = url
      document.getElementById("openNewWindow").click(); //点击事件
      // document.getElementById("openNewWindow").remove();
    },
    // 关闭修改图书弹窗
    closeBookModal() {
      this.editBookVisible = false
    },
    // 确认修改图书
    async submitBookModal(text) {
      const res = await checkBookName({
        bookId: this.tipsBookId,
        newBookName: text
      })
      if (res.code == 489) {
        this.$message.error(res.msg)
      } else {
        this.editBookVisible = false
        this.tipsStep = 'one'
        this.tipsVisible = true
        this.newBookName = text
        this.handleTipsCallBack()
      }
    },
    handleTipsOcrClose() {
      this.tipsOcrVisible = false
    },
    async handleTipsOcrCallBack(type) {
      if (type != 'ok') {
        this.tipsOcrVisible = false
        return
      }
      this.tipsOcrVisible = false
      this.tipsVisible = true

      try {
        if (this.titleType === 'bookProgressSimple' || this.titleType === 'bookProgressComplexity') { // 繁转简
            const res = await textTransform({
              bookId: this.tipsBookId,
              isWait: 1,
              newBookName: this.newBookName || undefined,
              fanJianStatus: this.itm.fanJianStatus
            })

            if (res.code == 489) {
              this.tipsVisible = false
              this.editBookVisible = true
              this.bookRepeatMsg = res.msg
              return
            }
          
            this.newBookName = ''
            
        } else if (this.titleType === 'bookProgressBd') { // 标点
          await textAutopun({
            bookId: this.tipsBookId,
            punType: 1,
            isWait:  1
          })
        } else if (this.titleType=== 'bookProgressBx') { // 标线
          await textAutopun({
            bookId: this.tipsBookId,
            punType: 2,
            isWait: 1
          })
        }
      } catch (error) {
        this.tipsVisible = false
      }

      // 避免点击稍候查看按钮重复提示
      if (this.tipsVisible) {
        this.tipsVisible = false
        this.$message.success('处理完成');
        this.$emit('refresh')
      }
    }
  }
}
</script>
<style lang="less">
.list-wrap {
  width: 100%;
  height: 220px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  .list-item {
    margin-left: 41px;
    flex: 1;
    height: 100%;
    background: #FFFFFF;
    border: 1px solid #E9DDD2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    .simple {
      width: 80px;
      height: 34px;
      background: #6868CE;
      font-size: 16px;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 34px;
      text-align: center;
      position: absolute;
      left: -1px;
      top: -1px;
      z-index: 1;
    }

    .list-left {
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
      border-right: 1px dashed #E9DDD2;

      .left {
        padding: 12px 18px;
        position: relative;
        width: 235px;
        height: 151px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          .upload {
            display: block;
          }
        }

        .upload {
          display: none;
          cursor: pointer;
          input {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            z-index: 1;
            cursor: pointer;
          }
        }
        
        .cover {
          max-width: 199px;
          max-height: 127px;
        }

        .img {
          width: 29px;
          height: 29px;
          position: absolute;
          right: 18px;
          top: 12px;
          cursor: pointer;
        }

        div {
          width: 113px;
          height: 34px;
          background: url(../assets/upbeijing.png) no-repeat;
          background-size: cover;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-52px, -17px);
          opacity: 0.67;

          span {
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
            line-height: 34px;
            margin-left: 20px;
          }
        }
      }

      .right {
        flex: 1;
        height: 100%;
        margin-left: 42px;
        padding: 12px 0px 12px 18px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .bookName {
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          div {
            flex: 8px 0 0;
            width: 8px;
            height: 8px;
            background: #FFCD04;
            border-radius: 50%;
            margin-right: 5px;
          }

          span {
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #6868CE;
            line-height: 22px;
          }
        }

        .bookContent {
          width: 100%;
          font-size: 12px;
          font-weight: 400;
          color: #666666;
          line-height: 21px;
          padding-bottom: 21px;
          border-bottom: 1px dashed #C6C4C4;
          text-align: left;
          span {
            margin-right: 8px;
          }
        }

        .bookSchedule {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 100%;

          span {
            font-size: 12px;
            font-weight: 400;
            color: #333333;
            line-height: 21px;
            margin-right: 8px;
          }

          img {
            width: 19px;
            height: 19px;
            margin-left: 10px;
            // cursor: pointer;
          }
        }
      }
    }

    .list-right {
      width: 468px;
      padding-left: 26px;
      text-align: left;
      display: flex;
      flex-wrap: wrap;
      .step-click {
        width: 120px;
        height: 34px;
        border: 1px solid #333333;
        color: #333333;
        margin-bottom: 15px;
        margin-right: 30px;
        padding: 0 8px;
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        &:nth-of-type(3n) {
          margin-right: 0px;
        }
        &:hover {
          box-shadow: 0 0 2px 2px rgba(51, 51, 51, 0.2);
        }
        &.active {
          color: #4187F1;
          border: 1px solid #5C96FA;
        }
        &.active:hover {
          box-shadow: 0 0 2px 2px rgba(92, 150, 250, 0.2);
        }
        &.error {
          color: #fa635c;
          border: 1px solid #fa635c;
        }
        &.error:hover {
          box-shadow: 0 0 2px 2px rgba(250, 99, 92, 0.2);
        }
        img {
          width: 19px;
          height: 19px;
          margin-left: 10px;
          margin-right: 10px;
        }
      }
    }

    .jump-btn {
      border: 1px solid red !important;
      color: #000 !important;
    }
  }
}
</style>