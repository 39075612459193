import request from '@/utils/request'

// 加入的机构列表
export function getJoinList(data) {
  return request({
    url: 'institution/user/join/list',
    method: 'GET',
    data,
  })
}

// 加入的机构列表
export function getJoinSelect(data) {
  return request({
    url: 'institution/user/join/select',
    method: 'GET',
    data,
  })
}

// 切换默认机构
export function updateDefault(data) {
  return request({
    url: `institution/user/update/default/${data.id}`,
    method: 'POST',
    data,
  })
}

// 机构管户-用户审核列表
export function getManagerList(data) {
  return request({
    url: `institution/manager/join/list`,
    method: 'GET',
    data,
  })
}

// 机构管户-用户审核-切换是否受限制
export function updateLimit(data) {
  return request({
    url: `institution/manager/update/limit/${data.id}`,
    method: 'POST',
    data,
  })
}

// 机构管户-审核申请
export function auditApply(data) {
  return request({
    url: `institution/manager/audit/apply`,
    method: 'POST',
    data,
  })
}

// 机构管理-添加机构会员列表
export function getApplyList(data) {
  return request({
    url: `institution/manager/wait/list`,
    method: 'GET',
    data,
  })
}

// 机构管理-授权确认（失败）
export function toInstitutionJoin(data) {
  return request({
    url: `/institution/manager/to/join`,
    method: 'POST',
    data,
  })
}

// 机构审核-查看用户申请的详细信息
export function getUserDetail(data) {
  return request({
    url: `institution/user/detail/${data.id}`,
    method: 'GET',
    data,
  })
}

// 智能处理，消费检测接口
export function consumeCheck(data) {
  return request({
    url: `institution/consume/check`,
    method: 'POST',
    data,
  })
}

// 机构管理-机构使用记录
export function getConsumeInstitution(data) {
  return request({
    url: `institution/consume/institution`,
    method: 'GET',
    data,
  })
}

// 机构列表
export function getInstitutionList(data) {
  return request({
    url: `institution/item/list`,
    method: 'GET',
    data,
  })
}



