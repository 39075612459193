var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.list),function(item,index){return _c('div',{key:item.id,staticClass:"list-wrap"},[_c('Checkbox',{attrs:{"checked":_vm.checkedList.indexOf(item.id) == -1 ? false : true},on:{"change":function($event){return _vm.handleChange($event, item.id)}}}),_c('div',{staticClass:"list-item"},[_vm._v(" "+_vm._s(item.bookBrogressSimple)+" "),(item.bookIsSimple == 1)?_c('span',{staticClass:"simple"},[_vm._v("简体")]):_vm._e(),(item.bookIsSimple == 0)?_c('span',{staticClass:"simple"},[_vm._v("繁体")]):_vm._e(),_c('div',{staticClass:"list-left"},[_c('div',{staticClass:"left"},[_c('img',{staticClass:"cover",attrs:{"src":item.coverImage ? 
            ("" + _vm.imgPath + (item.coverImage))
            : require('@/assets/image-default.png')}}),(item.bookProgressOcr != 2 && item.bookType == 0)?_c('div',{staticClass:"upload"},[_c('input',{attrs:{"type":"file","multiple":"multiple","id":"files","accept":"image/png,image/jpeg,image/tiff,image/tif,application/pdf,.djvu"},on:{"change":function($event){return _vm.handleImgChange(item.id, item.countPage, $event)}}}),_c('span',[_vm._v("上传文件")])]):_vm._e(),_c('img',{staticClass:"img",attrs:{"src":require("@/assets/biaoqian.png"),"alt":""},on:{"click":function($event){return _vm.handleEdit(item.id)}}})]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"bookName"},[_c('div'),_c('span',[_vm._v(" 《"+_vm._s(item.bookName)+"》 ")])]),_c('div',{staticClass:"bookContent"},[(item.bookType == 1)?[_c('span',[_vm._v("格式："+_vm._s(item.bookFormal))]),_c('span',[_vm._v("字数："+_vm._s(item.countWord))]),_c('span',[_vm._v("创建时间："+_vm._s(item.sysCreateTime))]),(item.sysHandleTime)?_c('span',[_vm._v("最新保存："+_vm._s(item.sysHandleTime))]):_vm._e(),(item.endHandleTime)?_c('span',{staticStyle:{"color":"#666"}},[_vm._v("保存期限："+_vm._s(item.endHandleTime))]):_vm._e()]:[_c('span',[_vm._v("页数："+_vm._s(item.countPage)+"页")]),_c('span',[_vm._v("识别页数："+_vm._s(item.countOcrPage))]),_c('span',[_vm._v("字数："+_vm._s(item.countWord))]),_c('span',[_vm._v("修改字数："+_vm._s(item.countEdit))]),_c('span',[_vm._v("创建时间："+_vm._s(item.sysCreateTime))]),(item.sysHandleTime)?_c('span',[_vm._v("最新保存："+_vm._s(item.sysHandleTime))]):_vm._e(),(item.endHandleTime)?_c('span',{staticStyle:{"color":"#666"}},[_vm._v("保存期限："+_vm._s(item.endHandleTime))]):_vm._e()]],2),_c('div',{staticClass:"bookSchedule"},[_c('span',[_vm._v("进度")]),_vm._l((_vm.scheduleArr),function(itm){return (itm.title != 'bookProgressEdit')?_c('img',{key:itm.title,attrs:{"src":item[itm.title] === 0 ? itm.unChecked
              : item[itm.title] === 1 ? itm.checked 
              : item[itm.title] === 2 ? itm.error : ''}}):_vm._e()})],2)])]),_c('div',{staticClass:"list-right"},[_vm._l((_vm.scheduleArr),function(itm){return [(itm.title == 'bookProgressImg')?void 0:((itm.title == 'bookProgressSimple'&& item.transSimpleFlag == 1) 
          || (itm.title == 'bookProgressComplexity' && item.transComplexityFlag == 1))?[_c('div',{staticClass:"step-click active",on:{"click":function($event){return _vm.firstTips(itm, item)}}},[_c('img',{attrs:{"src":itm.checked}}),_c('span',[_vm._v(_vm._s(itm.text))])])]:(item[itm.type] == 1)?[_c('div',{staticClass:"step-click active",on:{"click":function($event){return _vm.firstTips(itm, item)}}},[_c('img',{attrs:{"src":itm.checked}}),_c('span',[_vm._v(_vm._s(itm.text))])])]:_vm._e()]}),(item.editFlag == 1)?_c('div',{staticClass:"step-click active jump-btn",on:{"click":function($event){return _vm.jumpTx(item.id)}}},[_c('img',{attrs:{"src":require("@/assets/icon-tx.jpg")}}),_c('span',[_vm._v("腾讯文档")])]):_vm._e()],2)])],1)}),(_vm.editVisible)?_c('AddOrEditModal',{attrs:{"type":"edit","visible":_vm.editVisible,"id":_vm.editId,"handleClose":_vm.handleClose,"handleOcrList":_vm.handleOcrList}}):_vm._e(),(_vm.tipsVisible)?_c('HintOrDownModal',{attrs:{"title":"提示","tipsType":_vm.tipsStep == 'first' ? 'tipsFourteen'
      : (_vm.titleType == 'bookProgressSimple' || _vm.titleType == 'bookProgressComplexity' ) && _vm.tipsStep == 'one' ? 'tipsFive'
      : _vm.tipsStep == 'one' ? 'tipsThirteen'
      : _vm.tipsStep == 'loading' ? 'tipsTen'
      : 'tipsNine',"visible":_vm.tipsVisible,"handleClose":_vm.handleTipsClose,"handleCallback":_vm.handleTipsCallBack,"fanJianStatus":_vm.tipsStep != 'loading' ? _vm.itm.fanJianStatus : ''}}):_vm._e(),(_vm.imgTipsVisible)?_c('TipsModal',{attrs:{"visible":_vm.imgTipsVisible,"tipsType":"tipsFour","buttonText":"我知道了","handleClose":_vm.tipsImageCallBack,"limitImageObj":_vm.limitImageObj}}):_vm._e(),(_vm.upFailVisible)?_c('UploadModal',{attrs:{"visible":_vm.upFailVisible,"handleClose":_vm.closeUploadModal,"exceedFiveList":_vm.exceedFiveList,"jamList":_vm.jamList,"type":_vm.upFailCondition,"fileFailCount":_vm.fileFailCount}}):_vm._e(),(_vm.editBookVisible)?_c('EditBookModal',{attrs:{"visible":_vm.editBookVisible,"msg":_vm.bookRepeatMsg,"handleClose":_vm.closeBookModal},on:{"handleOk":_vm.submitBookModal}}):_vm._e(),_c('HintOcrModal',{attrs:{"visible":_vm.tipsOcrVisible,"handleClose":_vm.handleTipsOcrClose,"handleCallback":_vm.handleTipsOcrCallBack,"ocrLimitObj":_vm.limitObj}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }