<!--
 * @Author: wb_guochuang wb_guochuang@kuaishou.com
 * @Date: 2022-06-05 17:57:07
 * @LastEditors: wb_guochuang wb_guochuang@kuaishou.com
 * @LastEditTime: 2022-07-12 22:28:38
 * @FilePath: \personal\src\components\AddOrEditModal.vue
 * @Description: 提示 
-->
<template>
<div>
  <a-modal
    class="up-modal"
    :width="540"
    title="提示"
    :visible="visible"
    :maskClosable="false"
    @cancel="handleClose"
  >
    <template slot="footer">
      <div class="button">
        <div @click="handleClose">我知道了</div>
      </div>
    </template>
    <div class="modal-content">
     <div>本次上传图像失败{{fileFailCount}}页，具体原因如下：
        <div>
            <div class="retract" v-if="exceedFiveList.length">以下图像大小超出5M，未能成功上传。请您使用图像处理工具调整大小后，再次尝试。</div>
            <div v-if="exceedFiveList.length">详情：</div>
            <div style="margin: 10px 0;">
              <div v-for='(item, index) in exceedFiveList' :key="index">
              【{{item}}】
              </div>
            </div>
            <div class="retract" v-if="jamList.length">以下图像由于上传通道拥堵，未能成功上传，请稍候重试。</div>
            <div v-if="jamList.length">详情：</div>
            <div style="margin: 10px 0;">
              <div v-for='(item, index) in jamList' :key="index">
              【{{item}}】
              </div>
            </div>
        </div>

        <div class="retract"> 补传成功后，系统会根据本书内所有图像名称自动排序。</div>
        <div class="retract">建议您使用古联快传客户端上传，单次即可上传600页，支持断点续传，更为方便。</div>
     </div>
    </div>
  </a-modal>
</div>
  
</template>

<script>
export default {
  props: [
    'visible',
    'handleClose',
    'exceedFiveList',
    'jamList',
    'fileFailCount'
  ],
  data() {
    return {
     
    }
  },
  methods: {
  
  }
}
</script>

<style lang="less">
.up-modal {
  .ant-modal-content {
    .ant-modal-header {
      border:  none;
      .ant-modal-title {
        width: 80px;
        height: 32px;
        border-bottom: 5px solid #6868CE;
        display: flex;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        color: #6868CE;
      }
    }

    .ant-modal-footer {
      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 46px;

        div {
          width: 84px;
          height: 34px;
          border: 1px solid #9B9B9B;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-weight: 500;
          color: #9B9B9B;
          cursor: pointer;
        }

        :nth-child(1) {
          background: url(../assets/beijing.png) no-repeat;
          background-size: cover;
          color: #fff;
          border: none;
        }
      }
    }

    .modal-content {
      width: 100%;
      min-height: 160px;
      max-height: 300px;
      overflow-y: auto;

      .retract {
        text-indent: 30px;
        line-height: 24px;
      }
    }
  }
}

</style>