<!--
 * @Author: wb_guochuang wb_guochuang@kuaishou.com
 * @Date: 2022-06-05 17:57:07
 * @LastEditors: wb_guochuang wb_guochuang@kuaishou.com
 * @LastEditTime: 2022-07-12 22:28:38
 * @FilePath: \personal\src\components\AddOrEditModal.vue
 * @Description: 下载
-->
<template>
<div>
  <a-modal
    class="tips-down-modal"
    :width="360"
    title="下载"
    :visible="visible"
    :maskClosable="false"
    @cancel="handleClose"
  >
    <template slot="footer">
      <div  class="button">
        <div @click="handleClose" class="cancle">取消</div>
        <div @click="handleOk" class="ok">确定</div>
      </div>
    </template>
   
    <div class="modal-content">
      <div class="content">
        <div v-html="tipsContent" />
        <div class="radio">
          <a-radio-group v-model="value">
            <a-radio :value="0">
              Xml文件
            </a-radio>
            <a-radio :value="1">
              Doc文件
            </a-radio>
          </a-radio-group>
        </div>
      </div>
    </div>
  </a-modal>
</div>
  
</template>

<script>
export default {
  props: [
    'visible',
    'buttonText',
    'handleClose',
    'handleCallback',
    'bookNameList'
  ],
  data() {
    return {
      tipsContent: `即将为您下载${this.bookNameList}的最新编辑成果，请选择要下载的文件格式：`,
      /** 单选框 */
      value: 1,
      agreeCheck: 0
    }
  },
  methods: {
    /** 提交 */
    handleOk() {
      if (this.value === undefined) {
        this.$message.warning('请先选择下载类型');
      } else {
        this.handleCallback(this.value)
      } 
    },
  }
}
</script>

<style lang="less">
.tips-down-modal {
  .ant-modal-content {
    .ant-modal-header {
      border:  none;
      .ant-modal-title {
        width: 80px;
        height: 32px;
        border-bottom: 5px solid #6868CE;
        display: flex;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        color: #6868CE;
      }
    }

    .ant-modal-footer {
      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 46px;

        
        div {
          width: 84px;
          height: 34px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-weight: 500;
          color: #9B9B9B;
          cursor: pointer;
        }

        .cancle {
          border: 1px solid #9B9B9B;
          margin-right: 50px;
        }


        .ok {
          background: url(../assets/beijing.png) no-repeat;
          background-size: cover;
          color: #fff;
          border: none;
        }

        div {
          &:nth-of-type(1):hover {
            border: 1px solid #6868CE;
            color: #6868CE;
          }
          &:nth-of-type(2):hover {
            opacity: 0.9;
          }
        }
      }
    }

    .modal-content {
      width: 100%;
      min-height: 160px;
      max-height: 300px;
      overflow-y: auto;

      .content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 28px;
        margin-top: 20px;

        .radio {
          width: 100%;
          height: 100px;
          display: flex;
          justify-content: space-between;
          margin-top: 26px !important;

          .ant-radio-group {
            width: 100%;
            display: flex;
            .ant-radio-wrapper:nth-of-type(2) {
              margin-left: 79px;
            }
          }
        }
      }
    }
  }
}

</style>