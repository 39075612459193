<!--
 * @Author: wb_guochuang wb_guochuang@kuaishou.com
 * @Date: 2022-06-05 17:57:07
 * @LastEditors: wb_guochuang wb_guochuang@kuaishou.com
 * @LastEditTime: 2022-06-08 23:05:00
 * @FilePath: \personal\src\components\AddOrEditModal.vue
 * @Description: 新增图书 or 修改图书 弹窗
-->
<template>
<div>
  <a-modal
    class="book-add"
    :width="type === 'add' ? 360 : 520"
    :title="type === 'add' ? '添加图书' : '修改'"
    :visible="visible"
    @cancel="handleClose"
  >
    <template slot="footer">
      <div class="button">
        <div @click="handleClose">取消</div>
        <div  @click="handleOk" html-type="submit">确定</div>
      </div>
    </template>
    <div class="form-content" v-if="type === 'add'">
      <a-form :form="form" :layout="formLayout" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
        <a-form-item label="请输入图书名称" class="book-name">
          <a-input
            v-decorator="['bookName', { rules: [{ required: true, message: '请输入图书名称' }] }]"
            placeholder="不超过20个字"
            :maxLength="20"
          />
        </a-form-item>
      </a-form>
    </div>
    <div  class="form-content" v-if="type !== 'add'">
      <a-form :form="form" :layout="formLayout" :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }">
        <a-form-item label="题名" >
          <a-input
            v-decorator="['bookName', { rules: [{ required: true, message: '请输入题名' }] }]"
            placeholder="不超过20个字"
            :maxLength="20"
          />
        </a-form-item>
        <a-form-item label="责任者" >
          <a-input
            v-decorator="['responsibility']"
            placeholder="不超过20个字"
            :maxLength="20"
          />
        </a-form-item>
        <a-form-item label="版本" >
          <a-input
            v-decorator="['version']"
            placeholder="不超过20个字"
            :maxLength="20"
          />
        </a-form-item>
        <a-form-item label="丛书" >
          <a-input
            v-decorator="['series']"
            placeholder="不超过20个字"
            :maxLength="20"
          />
        </a-form-item>
        <a-form-item label="装订形式" >
          <a-input
            v-decorator="['binding']"
            placeholder="不超过20个字"
            :maxLength="20"
          />
        </a-form-item>
        <a-form-item label="馆藏" >
          <a-input
            v-decorator="['collection']"
            placeholder="不超过20个字"
            :maxLength="20"
          />
        </a-form-item>
        <a-form-item label="其他" >
          <a-input
            v-decorator="['other']"
            placeholder="不超过20个字"
            :maxLength="20"
          />
        </a-form-item>
      </a-form>
    </div>
  </a-modal>
</div>
  
</template>

<script>
import { addBook, bookDetail, bookEdit } from '@/api/ocr.js'
export default {
  props: ['visible', 'handleClose', 'type', 'handleOcrList', 'id', 'addOcrList'],

  mounted() {
    if (this.type !== 'add') {
      this.formLayout = 'horizontal'
      this.handleBookDetail(this.id)
    } else {
      this.formLayout = 'vertical'
    }
  },

  updated() {
    if (this.type !== 'add') {
      this.formLayout = 'horizontal'
    } else {
      this.formLayout = 'vertical'
    }
  },

  data() {
    return {
      form: this.$form.createForm(this, { name: 'coordinated' }),
      formLayout: 'vertical',
      checkedOne: false
    }
  },

  methods: {
    /** 提交 */
    handleOk() {
     this.handleSubmit();
    },
    /** 确定 */
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (err) {
          console.log('Received values of form: ', values);
        } else {
            if (this.type === 'add') this.handleAddBook(values)
            if (this.type !== 'add') this.handleBookEdit(values)
        }
      });
    },
    /** 新增图书 */
    async handleAddBook(values) {
      // 只能点击一次
      if (this.checkedOne) {
        return 
      }

      this.checkedOne = true
      try {
        const r = await addBook(values);
        this.$message.success(r.msg);
        this.handleClose();
        this.handleOcrList();
        this.checkedOne = false
      } catch (error) {
        this.checkedOne = false
      }
      
    },
    /** 图书详情 */
    async handleBookDetail (id) {
      const r = await bookDetail({id})
      // 兼容form 不允许对表单以外的字段进行赋值 否则有报错
      const { bookName, responsibility, version, series, binding, collection, other } = r.data
      this.form.setFieldsValue({
        bookName, responsibility, version, series, binding, collection, other
      })
    },
    /** 编辑图书 */
    async handleBookEdit(values) {
      const r = await bookEdit({id: this.id, ...values});
      this.$message.success('编辑成功');
      this.handleClose();
      this.handleOcrList();
    }
  },
}
</script>

<style lang="less">
.book-add {
  .ant-modal-content {
    .ant-modal-header {
      border:  none;
      .ant-modal-title {
        width: 80px;
        height: 32px;
        border-bottom: 5px solid #6868CE;
        display: flex;
        justify-content: center;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #6868CE;
      }
    }

    .ant-modal-footer {
      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 46px;

        div {
          width: 84px;
          height: 34px;
          border: 1px solid #9B9B9B;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #9B9B9B;
          cursor: pointer;
        }

        :nth-child(2) {
          background: url(../assets/beijing.png) no-repeat;
          background-size: cover;
          color: #fff;
          border: none;
          margin-left: 50px;
        }
      }
    }

    .form-content {
      width: 100%;
      min-height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;

      .ant-form {
        width: 100%;
        height: 100%;
      }

      .ant-form-vertical {
        .ant-form-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          
        }
      }
      .book-name .ant-form-item-label {
        width: 245px;
        text-align: left;
      }
    }
  }
}
</style>