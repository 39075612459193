import request from '@/utils/request'

// 获取ocr图书列表
export function ocrBookList(data) {
  return request({
    url: 'metabook/ocrlist',
    method: 'get',
    data,
  })
}

// 新增ocr 图书
export function addBook(data) {
  return request({
    url: 'metabook/add',
    method: 'POST',
    data,
  })
}

// 图书详情
export function bookDetail(data) {
  return request({
    url: `metabook/${data.id}`,
    method: 'get',
    data,
  })
}

// 编辑图书
export function bookEdit(data) {
  return request({
    url: 'metabook/edit',
    method: 'POST',
    data,
  })
}

// 获取文本处理图书列表
export function getTextlist(data) {
  return request({
    url: 'metabook/textlist',
    method: 'GET',
    data,
  })
}

// 繁转简
export function textTransform(data) {
  return request({
    url: 'text/transform',
    method: 'POST',
    data,
  })
}

// 标点标线
export function textAutopun(data) {
  return request({
    url: 'text/autopun',
    method: 'POST',
    data,
  })
}

// 图片列表
export function getImglist(data) {
  return request({
    url: 'ocr/imglist',
    method: 'get',
    data,
  })
}

// 下载
export function ocrDownload(data) {
  return request({
    url: `ocr/download/${data.bookId}/${data.format}`,
    method: 'get',
  })
}

// ocr识别
export function ocr(data) {
  return request({
    url: `ocr/ocr`,
    method: 'post',
    data,
  })
}

// 删除图片
export function deleteImgOcr(data) {
  return request({
    url: `ocr/deleteImg/${data.ids}`,
    method: 'post',
    data,
  })
}

// 删除图书
export function deleteBook(data) {
  return request({
    url: `metabook/${data.ids}`,
    method: 'DELETE',
  })
}

// 图片排序
export function doSort(data) {
  return request({
    url: `ocr/dosort/${data.id}/${data.sort}`,
    method: 'post',
    data,
  })
}

// 图片排序
export function uploadImgs(data) {
  return request({
    url: `ocr/uploadImgs/${data.bookId}`,
    method: 'POST',
    data,
  })
}

// 工作总览列表
export function getWorkList(data) {
  return request({
    url: `metabook/worklist`,
    method: 'get',
    data,
  })
}

// 在线编校
export function getEditList(data) {
  return request({
    url: `metabook/getEditList`,
    method: 'get',
    data,
  })
}

// 获取在线编辑器进度
export function getOcrProgress(data) {
  return request({
    url: `ocr/progress/${data.bookId}/${data.uuid}`,
    method: 'get',
    data,
  })
}

// 获取图书 图片 文本数
export function getDelMsg(data) {
  return request({
    url: `metabook/delMsg/${data}`,
    method: 'get',
    data,
  })
}

// ocr图文对照列表
export function getOcrCheckList(data) {
  return request({
    url: `ocr/ocrCheckList`,
    method: 'get',
    data,
  })
}

// 弹窗提醒
export function modalAffirm(data) {
  return request({
    url: `metabook/AgreeSafeInfo`,
    method: 'post',
    data,
  })
}

// 完成本页
export function completePage(data) {
  return request({
    url: `ocr/toText/${data.bookId}`,
    method: 'post',
    data,
  })
}

// 文本编辑按钮是否点击过
export function metabookProgress(data) {
  return request({
    url: `metabook/progress/${data.bookid}/${data.button}`,
    method: 'get',
    data,
  })
}

// ocr识别前检测是否够
export function beforeCheck(data) {
  return request({
    url: `ocr/ocr/before/check`,
    method: 'post',
    data,
  })
}

// office跳转验证
export function weboffice(data) {
  return request({
    url: `wps/url`,
    method: 'get',
    data,
  })
}

// tencent
export function tencent(data) {
  return request({
    url: `tencent`,
    method: 'get',
    data,
  })
}

// 飞书打开
export function feishu(data) {
  return request({
    url: `feishu/jump`,
    method: 'get',
    data,
  })
}

// 繁简转换前验证图书名是否重复
export function checkBookName(data) {
  return request({
    url: `/text/checkBookName`,
    method: 'GET',
    data,
  })
}

// 文件处理上传
export function uploadFile(data) {
  return request({
    url: `/meta/upload/file`,
    method: 'POST',
    data,
  })
}

// 文件上传大小限制
export function queryMaxBookStorageLimit(data) {
  return request({
    url: `/meta/upload/maxBookStorageLimit`,
    method: 'get',
    data,
  })
}

// 上传文件校验限制
export function limitImage(data) {
  return request({
    url: `/meta/upload/limit/file`,
    method: 'get',
    data,
  })
}


// 上传图片校验限制
export function getLimitImageBook(data) {
  return request({
    url: `/ocr/limit/image`,
    method: 'get',
    data,
  })
}
