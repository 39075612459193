<!--
 * @Author: wb_guochuang wb_guochuang@kuaishou.com
 * @Date: 2022-06-05 17:57:07
 * @LastEditors: wb_guochuang wb_guochuang@kuaishou.com
 * @LastEditTime: 2022-07-12 22:28:38
 * @FilePath: \personal\src\components\AddOrEditModal.vue
 * @Description: 文本处理判断额度是否满足
-->
<template>
<div>
  <a-modal
    class="tips-modal1"
    :width="360"
    title="提示"
    :visible="visible"
    :maskClosable="false"
    @cancel="handleClose"
  >
    <template slot="footer">
      <div class="button">
        <div @click="handleClose">取消</div>
        <div @click="handleOk">{{ limitObj.userAccountDiff < 0 ? '充值' : '确定'}}</div>
      </div>
    </template>
    <div class="modal-content">
      <div 
        v-for="(item, index) in tipsArr[tipsType]" :key="index"
        class="content"
      >
        <div v-html="item" />
      </div>
    </div>
  </a-modal>

   <!-- 额度不够充值成功提示 -->
   <TipsModal
      v-if="rechargeOkFlag"
      :visible="rechargeOkFlag"
      tipsType="tipsSix"
      buttonText="我知道了"
      :handleClose="rechargeOkCallBack"
      :num="rechargeOkNum"
  />

  <Recharge
    v-if="rechargeFlag"
    @cancle="changeCancle"
    :pageData='{ [remainingData.price]: remainingData.needAvailableCount}'
  />

</div>
  
</template>
<script>
import TipsModal from '@/components/TipsModal.vue';
import Recharge from '@/views/recharge/index.vue';

export default {
  components: { 
    TipsModal,
    Recharge,
  },
  props: [
    'visible',
    'ocrLimitObj',
    'handleClose',
    'handleCallback'
  ],
  data() {
    return {
      limitObj: {
        allCount: 0,
        currentConsumeAmount: 0,
        userAccountAmount: 0,
        userAccountNeed: 0
      },
      tipsArr: {},
      tipsType: 'tipsOne',
      rechargeFlag: false,
      rechargeOkFlag: false,
      rechargeOkNum: 0,
    }
  },
  watch: {
    visible (val) {
      this.limitObj = this.ocrLimitObj 

      this.tipsArr =  {
        tipsOne: [`本次您选择的内容共计<b style="color: #6868CE;">${this.limitObj.allCount}</b>千字，共需<b style="color: #6868CE;">${this.limitObj.currentConsumeAmount}</b>文。选择对象中包含已处理过的文件，点击确定后将针对当前最新版本进行处理。开始智能处理后无法撤销。<br />确定开始智能处理吗？`], // （2）额度充足，重复时
        tipsTwo: [`本次您选择的内容共计<b style="color: #6868CE;">${this.limitObj.allCount}</b>千字，共需<b style="color: #6868CE;">${this.limitObj.currentConsumeAmount}</b>文。开始智能处理后无法撤销。<br />确定开始智能处理吗？`], // （2）额度充足，不重复时
        tipsThree: [`本次您选择的内容共计<b style="color: #6868CE;">${this.limitObj.allCount}</b>千字，共需<b style="color: #6868CE;">${this.limitObj.currentConsumeAmount}</b>文。您的个人账户有<b style="color: #6868CE;">${this.limitObj.userAccountAmount}</b>文，仍需<b style="color: #6868CE;">${this.limitObj.userAccountDiff * -1}</b>文。<br />是否前往充值？`], //（1）额度不足时，个人账户
        tipsFour: [`本次即将处理的内容共计<b style="color: #6868CE;">${this.limitObj.allCount}</b>千字，超出本${this.limitObj?.bsConsume == 'institution_limit_day' ? '日' : '月'}累计使用上限<b style="color: #6868CE;">${this.limitObj.limitOutAmount}</b>文。您的个人账户有<b style="color: #6868CE;">${this.limitObj.userAccountAmount}</b>文。<br />是否使用个人余额？`], //（1）额度不足时，超出使用上限
        tipsFive: [`本次即将处理的内容共计<b style="color: #6868CE;">${this.limitObj.allCount}</b>千字，超出机构余额<b style="color: #6868CE;">${this.limitObj.currentConsumeAmount - this.limitObj.institutionAccountAmount}</b>文。您的个人账户有<b style="color: #6868CE;">${this.limitObj.userAccountAmount}</b>文。<br />是否使用个人余额？`], //（1）额度不足时，机构额度不足
      }
      // user_full(个人余额充足),
      // institution_full(机构充足)
      // user_less(个人余额不足),
      // institution_limit(超出日(月)使用量上限)
      // institution_less(机构不足) 
      const { bsConsume, allCount, repeatCount } = this.limitObj
      if ((bsConsume == 'user_full' || bsConsume == 'institution_full') && repeatCount > 0) {
        this.tipsType = 'tipsOne'
      } else if ((bsConsume == 'user_full' || bsConsume == 'institution_full') && repeatCount == 0) {
        this.tipsType = 'tipsTwo'
      } else if (bsConsume == 'user_less') {
        this.tipsType = 'tipsThree'
      } else if (bsConsume == 'institution_limit_day' || bsConsume == 'institution_limit_month') {
        this.tipsType = 'tipsFour'
      } else if (bsConsume == 'institution_less') {
        this.tipsType = 'tipsFive'
      }
    }
  },
  methods: {
    /** 提交 */
    handleOk() {
      const { userAccountDiff, userAccountAmount } = this.limitObj
      if (userAccountDiff < 0) {
        this.remainingData = {
          price: userAccountDiff * -1,
          needAvailableCount: userAccountDiff * -1
        }
        this.handleCallback()
        this.remainingImageOk()
      } else {
        this.handleCallback('ok')
      }
    },
    // 充值完成
    changeCancle(num) {
      this.rechargeFlag = false
      if (num) {
        this.rechargeOkFlag = true
        this.rechargeOkNum = num
      }
    },
    remainingImageOk() {
      this.remainingFlag = false
      this.rechargeFlag = true
    },
    rechargeOkCallBack() {
      this.rechargeOkFlag = false
    },
  }
}
</script>

<style lang="less">
.tips-modal1 {
  .ant-modal-content {
    .ant-modal-header {
      border:  none;
      .ant-modal-title {
        width: 80px;
        height: 32px;
        border-bottom: 5px solid #6868CE;
        display: flex;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        color: #6868CE;
      }
    }

    .ant-modal-footer {
      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 46px;

        .tips-btn-cancle {
          margin-right: 50px;
        }

        div {
          width: 84px;
          height: 34px;
          border: 1px solid #9B9B9B;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-weight: 500;
          color: #9B9B9B;
          cursor: pointer;
        }

        div {
          &:nth-of-type(1):hover {
            border: 1px solid #6868CE;
            color: #6868CE;
          }
          &:nth-of-type(2):hover {
            opacity: 0.9;
          }
        }

        :nth-child(2) {
          background: url(../assets/beijing.png) no-repeat;
          background-size: cover;
          color: #fff;
          border: none;
          margin-left: 24px;
        }
      }
    }

    .modal-content {
      width: 100%;
      min-height: 160px;
      max-height: 300px;
      overflow-y: auto;
      display: flex;
      align-items: center;

      .content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 28px;
      }
    }
  }
}

</style>